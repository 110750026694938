import $ from 'jquery';

$(() => {
  const overlay = $('.menu-overlay'),
        navigation = $('#main-navigation'),
        dropdown = $('.header-nav .nav-item.dropdown'),
        submenu = $('#fractomenunew .dropdown-content > .col > ul > li');

  dropdown.on('mouseover', (event) => {
    if ('ontouchstart' in window) {
      return;
    }
    overlay.addClass('menu-overlay--show');
    $(event.currentTarget).addClass('open');
  });

  dropdown.on('mouseleave', (event) => {
    if ('ontouchstart' in window) {
      return;
    }
    overlay.removeClass('menu-overlay--show');
    navigation.removeClass('show');
    submenu.removeClass('open');
    $(event.currentTarget).removeClass('open');
  });

  submenu.on('mouseover', (event) => {
    if ('ontouchstart' in window) {
      return;
    }
    submenu.removeClass('open');

    $(event.currentTarget).addClass('open');
  });

  $('.header-nav .nav-link.dropdown-toggle').off('click').on('click', (event) => {
    event.preventDefault();

    if ($(this).attr('href') !== '#') {
      window.location = $(this).attr('href');
    }
  });

  overlay.on('click', () => {
    navigation.removeClass('show');
    overlay.removeClass('menu-overlay--show');

    setTimeout(() => {
      dropdown.removeClass('open');
    }, 250);
  });

  $('.js-navbar-close').on('click', () => {
    navigation.removeClass('show');
    overlay.removeClass('menu-overlay--show');

    setTimeout(() => {
      dropdown.removeClass('open');
    }, 250);
  });

  navigation.on('show.bs.collapse', () => {
    overlay.addClass('menu-overlay--show');
  });

  $('.dropdown-content > .col > div').on('click', (event) => {
    let parentOpen = $(event.currentTarget).closest('.open');
    parentOpen.removeClass('open');
  })

  $('.header-nav .nav-item.dropdown .nav-link').on('click', (event) => {
    if ('ontouchstart' in window) {
      event.preventDefault();
    }

    let wasOpen = $(event.currentTarget).parent().hasClass('open');

    dropdown.removeClass('open');
    overlay.removeClass('menu-overlay--show');

    if (!wasOpen) {
      $(event.currentTarget).parent().addClass('open');
      overlay.addClass('menu-overlay--show');
    }
  });

  $('.navbar-nav--new .dropdown-content > .col > ul > li > a').on('click', (event) => {
    if ('ontouchstart' in window) {
      event.preventDefault();
    }

    let wasOpen = $(event.currentTarget).parent().hasClass('open');

    $('.navbar-nav--new .dropdown-content > .col > ul > li').removeClass('open');

    if (!wasOpen) {
      $(event.currentTarget).parent().addClass('open');
    }
  });

  document.querySelectorAll('[data-obf]').forEach(el => {
    el.addEventListener('click', (item) => {
        const href = item.target.dataset.obf;
        window.location = str_rot13(href);
    });
  });

  function str_rot13(str) {
    return (str + '').replace(/[a-z]/gi, function (s) {
        return String.fromCharCode(s.charCodeAt(0) + (s.toLowerCase() < 'n' ? 13 : -13));
    });
  }

});
