import prestashop from 'prestashop';
import $ from 'jquery';
import Swiper from 'swiper';

prestashop.blockcart = prestashop.blockcart || {};

prestashop.blockcart.showModal = (html) => {
  function getBlockCartModal() {
    return $('#blockcart-modal');
  }

  let $blockCartModal = getBlockCartModal();
  if ($blockCartModal.length){
    $blockCartModal.remove();
  }

  $('body').append(html);

  $blockCartModal = getBlockCartModal();
  $blockCartModal.modal('show').on('hidden.bs.modal', (event) => {
    prestashop.emit('updateProduct', {
      reason: event.currentTarget.dataset
    });
  });

  const title = document.querySelector('#blockcart-modal .js-blockcart-title > span');

  if(title) {
    title.textContent = 'Les produits complémentaires à votre sélection';
  }

  const slider = document.querySelector('#blockcart-modal .js-product-slider');

  if (slider) {
    let navigation = {
      prevEl: slider.parentNode.querySelector('.swiper-button-prev'),
      nextEl: slider.parentNode.querySelector('.swiper-button-next'),
    };

    var config = {
      speed: 750,
      slidesPerGroup: 1,
      slidesPerView: 'auto',
      centerInsufficientSlides: true,
      breakpointsInverse: true,
      navigation: navigation,
      breakpoints: {
        500: {
          slidesPerGroup: 2,
          navigation: navigation,
        },
        992: {
            slidesPerGroup: 3,
            navigation: navigation,
        },
      }
    };

    if (slider.getAttribute('autoplay')) {
      config.autoplay = { delay: 5000 };
    }

    new Swiper(slider, config);
  }
};

